import billboard from 'billboard.js';
import numeral from 'numeral';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import th from 'date-fns/locale/th';
import en from 'date-fns/locale/en-US';

const dateLocales = {
  th,
  en,
};

numeral.register('locale', 'th', {
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  currency: {
    symbol: '฿',
  },
});
numeral.locale('th');

export const shortid = require('shortid');

export const isNumber = (value: string | number) => typeof numeral(value).value() === 'number';
export const isNull = (value: any) => value === null;

export const formatDate = (value: string, inputFormat = 'dd/MM/yyyy', outputFormat: string = 'dd MMMM yyyy', locale: string = 'th') => {
  const date = format(parse(value, inputFormat, new Date()), outputFormat, { locale: dateLocales[locale] });
  return date;
};

export const formatData = (value: string | number, numeralFormat = '0,0', nullValueReplacement = '-', prepend = null, append = null) => {
  if (isNull(value)) {
    return nullValueReplacement;
  }

  const formattedData = isNumber(value) ? `${prepend || ''}${numeral(value).format(numeralFormat)}${append || ''}` : value;

  return formattedData;
};

export const formatDecimal = (value: string | number, nullValueReplacement = '-') => {
  if (isNull(value)) {
    return nullValueReplacement;
  }

  const formattedDecimal = isNumber(value) ? numeral(value).format('0,0.00') : value;
  return formattedDecimal;
};

export const formatInteger = (value: string | number, nullValueReplacement = '-') => {
  if (isNull(value)) {
    return nullValueReplacement;
  }

  const formattedDate = isNumber(value) ? numeral(value).format('0,0') : value;
  return formattedDate;
};

export const extractKeyValuePairData = (fields: string[], data: Object) => {
  const dataSet: any = {};
  const propsData = {
    dataSet,
  };

  fields.forEach(field => {
    Object.assign(dataSet, { [field]: data[field] || '-' });
  });

  return propsData;
};

export const genChart = (configs: any) => {
  billboard.generate({ ...configs });
};

export const genDataId = () => `#data_${shortid.generate()}`;
